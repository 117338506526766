import httpService from '../../../helpers/http.service'

export default {
    resendVoucher(id) {
        return httpService.get(`resend-voucher/${id}`)
    },
    get(id = null, params = {}) {
        return httpService.get(`raise-money/list${id ? '/' + id : '' }`, {params})
    },
    getMyEnrollments(id = null, params = {}) {
        return httpService.get(`raise-money/my-list${id ? '/' + id : '' }`, {params})
    },
    getPaymentFinish(id = null, params = {}) {
        return httpService.get(`raise-money/payment-finish${id ? '/' + id : '' }`, {params})
    },
    getPaymentFinishItem(processedEnrollment = null, enrollment,  params = {}) {
        return httpService.get(`raise-money/payment-finish/${enrollment}/${processedEnrollment}`, {params})
    },
    getItem(processedEnrollment = null, enrollment,  params = {}) {
        return httpService.get(`raise-money/list/${enrollment}/${processedEnrollment}`, {params})
    },
    getMyItem(processedEnrollment = null, enrollment,  params = {}) {
        return httpService.get(`raise-money/my-list/${enrollment}/${processedEnrollment}`, {params})
    },
    getItemDetail(processedEnrollment = null, enrollment,  params = {}) {
        return httpService.get(`raise-money/my-list/${enrollment}/${processedEnrollment}/detail`, {params})
    },
    personsAssignments( subsidiary,params = {}) {
        return httpService.get(`subsidiaries/${subsidiary}/account-executives`, {params})
    },
    assigment(id = null, params = {}) {
        return httpService.post(`raise-money/${id}/assigment`, params)
    },
    getCountries() {
        return httpService.get(`select/countries/subsidiaries`)
    },
    updateDatePayment(enrollmentFee = null,   params = {}) {
        return httpService.put(`payments/${enrollmentFee}/update-date-pay`, params)
    },
    getMonitorEnrollments(params = {}) {
        return httpService.get(`raise-money/monitor`, {params})
    },
    getMonitorEnrollmentsDetail(id, params = {}) {
        return httpService.get(`raise-money/monitor/detail/${id}`, {params})
    },
    changeStatus(enrollmentFee = null,   params = {}) {
        return httpService.patch(`raise-money/fee/${enrollmentFee}/status`, params)
    },
    updateTypeCharge(processEnrollment = null,   params = {}) {
        return httpService.patch(`raise-money/processed/${processEnrollment}/typeCharge`, params)
    },
    typeAccount(processEnrollment = null,   params = {}) {
        return httpService.patch(`raise-money/processed/${processEnrollment}/type`, params)
    },
    downloadData(params) {
        return httpService.get(`reports/enrollment-data`, {params})
    },
    addAgreement(processEnrollment = null,   params = {}) {
        return httpService.post(`raise-money/processed/${processEnrollment}/agreement`, params)
    },
}
